import { createSlice } from "@reduxjs/toolkit";

const barChartsDataSlice = createSlice({
    name: "barChartsData",
    initialState: {
        barChartsData: "",
    },
    reducers: {
        setBarChartsData : (state, action) => {
            state.barChartsData = action.payload;
        }
    }
})

export const { setBarChartsData } = barChartsDataSlice.actions;


export const CurrentBarChartsData = (state) => state.barChartsData.barChartsData

export default barChartsDataSlice.reducer;