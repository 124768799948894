import React from 'react'
import Appbar from '../Appbar'
import { Stack, Box } from '@mui/material'
import PieChart from './Components/PieChart'
import GeoCharts from './Components/GeoCharts'
import VerticalBarCharts from './Components/VerticalBarCharts'


function ChartsandMaps() {


  return (
    <>
      <Appbar pageName="Charts and Maps" />
      <Stack alignItems={"center"}>
        <Box width={{ xs: "95%", md: "80%" }} sx={{ mt: 12 }}>
          <PieChart />
          <GeoCharts />
          <VerticalBarCharts />
        </Box>
      </Stack>
    </>
  )
}

export default ChartsandMaps