import { Box, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react'
import { Chart } from "react-google-charts";
import {CurrentGeoChartsData, setGeoChartsData} from '../../../redux/geoChartsDataSlice';
import { useDispatch, useSelector } from 'react-redux';


function GeoCharts() {
    const geoChartsData = useSelector(CurrentGeoChartsData)
    const dispatch = useDispatch()

    const datas = geoChartsData.map((e) => [e.countryInfo.iso3, e.cases])
    const skipNullValue = geoChartsData.filter((e) => e[0] !== null)
    console.log(skipNullValue)

    datas.unshift(["Country", "Cases"])

    console.log(datas)


    useEffect(() => {
        axios.get("https://disease.sh/v3/covid-19/countries").then((res) => {
            dispatch(setGeoChartsData(res.data))
        })
    }, [])

    const header = "Country Specific data of cases:"

    const data = [
        ["Country", "Cases"],
        ["Afghanistan", 221918],
        ["Albania", 334726],
        ["Algeria", 271841],
        ["Andorra", 48015],
        ["Angola", 105384],
        ["Anguilla", 3904],
        ["Antigua and Barbuda", 9106],
        ["Argentina", 10054251],
        ["Armenia", 449203],
        ["Aruba", 44180],
        ["Australia", 11622345],
        ["Austria", 6077908],
        ["Azerbaijan", 831859],
        ["Bahamas", 38084],
        ["Bahrain", 721961],
        ["Bangladesh", 2039244],
        ["Barbados", 107794],
        ["Belarus", 994037],
        ["Belgium", 4799762],
        ["Belize", 70782],
        ["Benin", 28014],
        ["Bermuda", 18860],
        ["Bhutan", 62671],
        ["Bolivia", 1199346],
        ["Bosnia", 402998],
        ["Botswana", 329905],
        ["Brazil", 37601257],
        ["British Virgin Islands", 7305],
        ["Brunei", 306333],
        ["Bulgaria", 1307793],
        ["Burkina Faso", 22056],
        ["Burundi", 53762],
        ["Cabo Verde", 63934],
        ["Cambodia", 138802],
        ["Cameroon", 125036],
        ["Canada", 4676878],
        ["Caribbean Netherlands", 11682],
        ["Cayman Islands", 31472],
        ["Central African Republic", 15368],
        ["Chad", 7701],
        ["Channel Islands", 101717],
        ["Chile", 5287596],
        ["China", 503302],
        ["Colombia", 6368934],
        ["Comoros", 9109],
        ["Congo", 25375],
        ["Cook Islands", 7120],
        ["Costa Rica", 1232576],
        ["Croatia", 1273795],
        ["Cuba", 1114309],
        ["Curaçao", 45986],
        ["Cyprus", 660854],
        ["Czechia", 4642224],
        ["Côte d'Ivoire", 88331],
        ["DRC", 96652],
        ["Denmark", 3181937],
        ["Diamond Princess", 712],
        ["Djibouti", 15690],
        ["Dominica", 15760],
        ["Dominican Republic", 661176],
        ["Ecuador", 1062275],
        ["Egypt", 516023],
        ["El Salvador", 201785],
        ["Equatorial Guinea", 17229],
        ["Eritrea", 10189],
        ["Estonia", 618931],
        ["Ethiopia", 500908],
        ["Falkland Islands (Malvinas)", 1930],
        ["Faroe Islands", 34658],
        ["Fiji", 68921],
        ["Finland", 1480370],
        ["France", 40096331],
        ["French Guiana", 98041],
        ["French Polynesia", 78581],
        ["Gabon", 48992],
        ["Gambia", 12626],
        ["Georgia", 1842046],
        ["Germany", 38428685],
        ["Ghana", 171657],
        ["Gibraltar", 20550],
        ["Greece", 6067218],
        ["Greenland", 11971],
        ["Grenada", 19693],
        ["Guadeloupe", 202836],
        ["Guatemala", 1253279],
        ["Guinea", 38563],
        ["Guinea-Bissau", 9614],
        ["Guyana", 73240],
        ["Haiti", 34246],
        ["Holy See (Vatican City State)", 29],
        ["Honduras", 472619],
        ["Hong Kong", 2904315],
        ["Hungary", 2202887],
        ["Iceland", 209191],
        ["India", 44991380],
        ["Indonesia", 6808308],
        ["Iran", 7611931],
        ["Iraq", 2465545],
        ["Ireland", 1712113],
        ["Isle of Man", 38008],
        ["Israel", 4826031],
        ["Italy", 25870833],
        ["Jamaica", 154993],
        ["Japan", 33803572],
        ["Jordan", 1746997],
        ["Kazakhstan", 1411831],
        ["Kenya", 343074],
        ["Kiribati", 5029],
        ["Kuwait", 665931],
        ["Kyrgyzstan", 206890],
        ["Lao People's Democratic Republic", 218259],
        ["Latvia", 977891],
        ["Lebanon", 1237556],
        ["Lesotho", 34790],
        ["Liberia", 8090],
        ["Libyan Arab Jamahiriya", 507263],
        ["Liechtenstein", 21446],
        ["Lithuania", 1320633],
        ["Luxembourg", 319959],
        ["MS Zaandam", 9],
        ["Macao", 7583],
        ["Macedonia", 348281],
        ["Madagascar", 68288],
        ["Malawi", 88728],
        ["Malaysia", 5100249],
        ["Maldives", 186651],
        ["Mali", 33150],
        ["Malta", 118756],
        ["Marshall Islands", 16098],
        ["Martinique", 229975],
        ["Mauritania", 63715],
        ["Mauritius", 42576],
        ["Mayotte", 42027],
        ["Mexico", 7621062],
        ["Micronesia", 26458],
        ["Moldova", 620574],
        ["Monaco", 16793],
        ["Mongolia", 1008655],
        ["Montenegro", 291898],
        ["Montserrat", 1403],
        ["Morocco", 1274180],
        ["Mozambique", 233417],
        ["Myanmar", 639122],
        ["N. Korea", 4772813],
        ["Namibia", 171310],
        ["Nauru", 5393],
        ["Nepal", 1003343],
        ["Netherlands", 8610372],
        ["New Caledonia", 79922],
        ["New Zealand", 2375191],
        ["Nicaragua", 18491],
        ["Niger", 9931],
        ["Nigeria", 266675],
        ["Niue", 820],
        ["Norway", 1484882],
        ["Oman", 399449],
        ["Pakistan", 1581053],
        ["Palau", 6009],
        ["Palestine", 621008],
        ["Panama", 1040230],
        ["Papua New Guinea", 46864],
        ["Paraguay", 810827],
        ["Peru", 4507363],
        ["Philippines", 4147129],
        ["Poland", 6517116],
        ["Portugal", 5588310],
        ["Qatar", 513500],
        ["Romania", 3404197],
        ["Russia", 22929149],
        ["Rwanda", 133194],
        ["Réunion", 486588],
        ["S. Korea", 31782676],
        ["Saint Helena", 2166],
        ["Saint Kitts and Nevis", 6602],
        ["Saint Lucia", 30052],
        ["Saint Martin", 12303],
        ["Saint Pierre Miquelon", 3452],
        ["Saint Vincent and the Grenadines", 9631],
        ["Samoa", 16763],
        ["San Marino", 24285],
        ["Sao Tome and Principe", 6587],
        ["Saudi Arabia", 841469],
        ["Senegal", 88997],
        ["Serbia", 2542214],
        ["Seychelles", 50937],
        ["Sierra Leone", 7762],
        ["Singapore", 2472873],
        ["Sint Maarten", 11030],
        ["Slovakia", 1866857],
        ["Slovenia", 1344044],
        ["Solomon Islands", 24575],
        ["Somalia", 27334],
        ["South Africa", 4076463],
        ["South Sudan", 18368],
        ["Spain", 13868227],
        ["Sri Lanka", 672482],
        ["St. Barth", 5494],
        ["Sudan", 63993],
        ["Suriname", 82513],
        ["Swaziland", 74670],
        ["Sweden", 2710457],
        ["Switzerland", 4405938],
        ["Syrian Arab Republic", 57608],
        ["Taiwan", 10239998],
        ["Tajikistan", 17786],
        ["Tanzania", 43078],
        ["Thailand", 4738988],
        ["Timor-Leste", 23450],
        ["Togo", 39499],
        ["Tokelau", 5],
        ["Tonga", 16817],
        ["Trinidad and Tobago", 191496],
        ["Tunisia", 1153323],
        ["Turkey", 17232066],
        ["Turks and Caicos Islands", 6588],
        ["Tuvalu", 2805],
        ["UAE", 1067030],
        ["UK", 24603076],
        ["USA", 107128863],
        ["Uganda", 170775],
        ["Ukraine", 5553031],
        ["Uruguay", 1038774],
        ["Uzbekistan", 253662],
        ["Vanuatu", 12019],
        ["Venezuela", 552695],
        ["Vietnam", 11614583],
        ["Wallis and Futuna", 3508],
        ["Western Sahara", 10],
        ["Yemen", 11945],
        ["Zambia", 343995],
        ["Zimbabwe", 265030]
    ]


    return (
        <>
            <Stack  mt={15}>
            <Typography sx={{ fontFamily: "ubuntu", fontWeight: "bold", fontSize: { xs: "18px", md: "30px", lg: "40px" } }}>{header.toLocaleUpperCase()}</Typography>
            <Stack alignItems={"center"} mt={{xs:0, md:3}}>
                <Box sx={{width:{xs:"95%",md:"90%"}}}>
                    <Chart
                        mapsApiKey='AIzaSyC5CAvszXQXedlueCrBhdR78jFGxgRUoTk'
                        chartEvents={[
                            {
                                eventName: "select",
                                callback: ({ chartWrapper }) => {
                                    const chart = chartWrapper.getChart();
                                    const selection = chart.getSelection();
                                    if (selection.length === 0) return;
                                    const region = data[selection[0].row + 1];
                                    console.log("Selected : " + region);
                                },
                            },
                        ]}
                        chartType="GeoChart"
                        width="100%"
                        height="400px"
                        data={data}
                        style={{ cursor: "pointer" }}
                    />
                </Box>
                </Stack>
            </Stack>
        </>
    )
}

export default GeoCharts