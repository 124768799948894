import {BrowserRouter, Routes, Route} from 'react-router-dom'
import HomePage from "./Pages/HomePage";
import ChartsandMaps from "./Pages/ChartsandMaps";

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage/>}></Route>
      <Route path='/chartsandmaps' element={<ChartsandMaps/>}></Route>
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
