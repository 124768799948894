import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import {CurrentBarChartsData, setBarChartsData} from '../../../redux/barChatsDataSlice';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function VerticalBarCharts() {
    const barChartsData = useSelector(CurrentBarChartsData)
    console.log(barChartsData);
    const dispatch = useDispatch()
    const header = "cases with date:"

    const filterCasesReport = barChartsData ? barChartsData.cases : false
    const filterDeathsReport = barChartsData ? barChartsData.deaths : false
    const filterRecoveredReport = barChartsData ? barChartsData.recovered : false
    console.log(filterCasesReport);

    const casesDataKey = Object.keys(filterCasesReport);
    const casesDataValue = Object.values(filterCasesReport);

    const deathsDataKey = Object.keys(filterDeathsReport);
    const deathsDataValue = Object.values(filterDeathsReport);

    const recoveredDataKey = Object.keys(filterRecoveredReport);
    const recoveredDataValue = Object.values(filterRecoveredReport);


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Cases',
            },
        },
    };


    const casesBarCharts = {
        labels : casesDataKey,
        datasets: [
            {
                label: 'Cases',
                data: casesDataValue,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    const deathsBarCharts = {
        labels : deathsDataKey,
        datasets: [
            {
                label: 'Deaths',
                data: deathsDataValue,
                backgroundColor: 'rgba(53, 162, 235, 0.5',
            }
        ],
    };

    const recoveredBarCharts = {
        labels : recoveredDataKey,
        datasets: [
            {
                label: 'Recovered',
                data: recoveredDataValue,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
        ]
    };




    useEffect(() => {
        axios.get("https://disease.sh/v3/covid-19/historical/all?lastdays=all").then((res) => {
            console.log(res)
            dispatch(setBarChartsData(res.data))
        })
    }, [])

    return (
        <>
            <Stack mt={15}>
                <Typography sx={{ fontFamily: "ubuntu", fontWeight: "bold", fontSize: { xs: "18px", md: "30px", lg: "40px" } }}>{header.toLocaleUpperCase()}</Typography>
                {casesDataKey && deathsDataKey && recoveredDataKey ?
                    <Stack alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 3 }}>
                        <Box sx={{ width: { xs: "95%", md: "90%" } }}>
                            <Bar options={options} data={casesBarCharts} style={{ width: "100%" }} />
                            <Bar options={options} data={deathsBarCharts} style={{ width: "100%" }} />
                            <Bar options={options} data={recoveredBarCharts} style={{ width: "100%" }} />
                        </Box>
                    </Stack> : <Typography>Loading ...</Typography>}
            </Stack>
        </>
    )
}

export default VerticalBarCharts