import { createSlice } from "@reduxjs/toolkit";

const geoChartsDataSlice = createSlice({
    name: "geoChartsData",
    initialState: {
        geoChartsData: [],
    },
    reducers: {
        setGeoChartsData : (state, action) => {
            state.geoChartsData = action.payload;
        }
    }
})

export const { setGeoChartsData } = geoChartsDataSlice.actions;


export const CurrentGeoChartsData = (state) => state.geoChartsData.geoChartsData

export default geoChartsDataSlice.reducer;