import { configureStore } from "@reduxjs/toolkit";
import pieChartsDataSlice from "./pieChartsDataSlice";
import geoChartsDataSlice from "./geoChartsDataSlice";
import barChatsDataSlice from "./barChatsDataSlice";


export default configureStore({
    reducer: {
        pieChartsData: pieChartsDataSlice,
        geoChartsData: geoChartsDataSlice,
        barChartsData: barChatsDataSlice
    }
  });
  