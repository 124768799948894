import { Stack, Box, TextField, Button, Dialog, DialogTitle, DialogContent, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Card, Avatar } from '@mui/material'
import { useState, useEffect, useRef } from 'react';
import generateUniqueId from "generate-unique-id";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from '../Appbar'
import CancelIcon from '@mui/icons-material/Cancel';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function HomePage() {
    const [openaddDialog, setOpenaddDialog] = useState(false)
    const [openviewDialog, setOpenviewDialog] = useState(false)
    const [openeditDialog, setOpeneditDialog] = useState(false)
    const [viewValue, setViewValue] = useState({
        firstName: "",
        lastName: "",
        status: ""
    })
    const [allUserData, setAllUserData] = useState([])
    console.log(allUserData)

    const [status, setStatus] = useState("Inactive")


    useEffect(() => {
        if ("data" in localStorage) {
            allUserData.length > 1 ? localStorage.setItem("data", JSON.stringify(allUserData)) : setAllUserData(JSON.parse(localStorage.getItem("data")))
        } else {
            localStorage.setItem("data", JSON.stringify([{ name: "tem1", id: "id-" + generateUniqueId({ length: 6, useLetters: false }) }, { name: "tem2", id: "id-" + generateUniqueId({ length: 6, useLetters: false }) }]))
        }

    }, [allUserData])



    const addDiaog = () => {
        setOpenaddDialog(true)
    }
    const [contactData, setContactData] = useState({
        firstName: { text: "", errorMsg: "", isValid: false },
        lastName: { text: "", errorMsg: "", isValid: false },
    })

    const handleFirstName = (e) => {
        if (e.target.value) {
            setContactData((previous) => ({
                ...previous,
                firstName: { text: e.target.value, errorMsg: "", isValid: false }
            }))

        }
        else {
            setContactData((previous) => ({
                ...previous,
                firstName: { text: e.target.value, errorMsg: "", isValid: false }
            }))
        }

    }

    const handleLastName = (e) => {
        if (e.target.value) {
            setContactData((previous) => ({
                ...previous,
                lastName: { text: e.target.value, errorMsg: "", isValid: false }
            }))

        }
        else {
            setContactData((previous) => ({
                ...previous,
                lastName: { text: e.target.value, errorMsg: "", isValid: false }
            }))
        }

    }


    const createContactTexfields = [{
        label: "First Name",
        onChangeFn: (e) => handleFirstName(e),
        value: contactData.firstName.text,
        errormsg: contactData.firstName.errorMsg,
        isValid: contactData.firstName.isValid,
        viewValue: viewValue.firstName

    }, {
        label: "Last Name",
        onChangeFn: (e) => handleLastName(e),
        value: contactData.lastName.text,
        errormsg: contactData.lastName.errorMsg,
        isValid: contactData.lastName.isValid,
        viewValue: viewValue.lastName

    }, {
        label: "Status",
        onChangeFn: (e) => setStatus(e.target.value),
        viewValue: viewValue.status

    }]


    const submitfn = () => {
        if (contactData.firstName.text === "") {
            setContactData((previous) => ({
                ...previous,
                firstName: { ...previous.firstName, errorMsg: "Enter Your First Name", isValid: true }
            }))
        }
        else if (contactData.lastName.text === "") {
            setContactData((previous) => ({
                ...previous,
                lastName: { ...previous.lastName, errorMsg: "Enter Your Last Name", isValid: true }
            }))
        }
        else {
            const id = "id-" + generateUniqueId({ length: 6, useLetters: false })
            const userData = {
                firstName: contactData.firstName.text,
                lastName: contactData.lastName.text,
                status: status,
                id: id
            }
            setAllUserData([...allUserData, userData])
            setOpenaddDialog(false)
            setContactData({
                firstName: { text: "", errorMsg: "", isValid: false },
                lastName: { text: "", errorMsg: "", isValid: false },
            })
            setStatus("Inactive")
        }
    }


    const handleClickView = (e) => {
        setOpenviewDialog(true)
        var viewvalue = allUserData.filter((el) => {

            return el.id === e
        })
        setViewValue({
            firstName: viewvalue[0].firstName,
            lastName: viewvalue[0].lastName,
            status: viewvalue[0].status
        })
    }

    const handleClickDelete = (e) => {
        const deleteArray = allUserData.filter((el) => {
            return el.id !== e
        })
        setAllUserData(deleteArray)

    }

    const [yourId, setYourId] = useState()

    const handleClickEdit = (e) => {
        setOpeneditDialog(true)
        setYourId(e)
        var sub = allUserData.filter((el) => {
            return el.id === e
        })
        setContactData({
            firstName: { text: sub[0].firstName, errorMsg: "", isValid: false },
            lastName: { text: sub[0].lastName, errorMsg: "", isValid: false },
        })
        setStatus(sub[0].status)

    }

    const handleUpdate = () => {
        if (contactData.firstName.text === "") {
            setContactData((previous) => ({
                ...previous,
                firstName: { ...previous.firstName, errorMsg: "Enter Your First Name", isValid: true }
            }))
        }
        else if (contactData.lastName.text === "") {
            setContactData((previous) => ({
                ...previous,
                lastName: { ...previous.lastName, errorMsg: "Enter Your Last Name", isValid: true }
            }))
        }
        else {
            const updated = allUserData.map((el) => {
                var updatedData = el.id === yourId ? { ...el, firstName: contactData.firstName.text, lastName: contactData.lastName.text, status: status } : el
                return updatedData
            })
            setAllUserData(updated)
            setOpeneditDialog(false)
            setContactData({
                firstName: { text: "", errorMsg: "", isValid: false },
                lastName: { text: "", errorMsg: "", isValid: false },
            })
            setStatus("Inactive")
        }

    }

    const clearDataFn = () => {
        localStorage.removeItem("data")
        window.location.reload()
    }

    return (
        <>
            <Appbar btn={addDiaog} pageName = "Conatct Page" />
            {allUserData.length > 2 ?
                <Stack alignItems={"center"}>
                    <Box width={"80%"} sx={{ mt: { xs: 5, md: 10 }, p: 5 }}>
                        <Stack mt={5} alignItems={"center"}>

                            <Box sx={{ width: { xs: "98%", md: "80%", lg: "70%", xl: "60%" } }}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 200 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>First Name</StyledTableCell>
                                                <StyledTableCell>Last Name</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                                <StyledTableCell align="center">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allUserData ? allUserData.slice(2).map((items) => (
                                                <StyledTableRow >
                                                    <StyledTableCell component="th" scope="row">
                                                        {items.firstName}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {items.lastName}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {items.status}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Button variant='contained' onClick={() => handleClickEdit(items.id)} sx={{ bgcolor: "black", borderRadius: "50px", ":hover": { bgcolor: "black" } }}>edit</Button>
                                                        <Button variant='contained' onClick={() => handleClickView(items.id)} sx={{ bgcolor: "black", borderRadius: "50px", ml: 2, ":hover": { bgcolor: "black" } }}>view</Button>
                                                        <Button variant='contained' onClick={() => handleClickDelete(items.id)} sx={{ bgcolor: "black", borderRadius: "50px", ml: 2, ":hover": { bgcolor: "black" } }}>delete</Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )) : "loading"}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
                :
                <Stack alignItems={"center"} justifyContent={"center"} height={"100vh"}>
                    <Card elevation={5} sx={{bgcolor:"#E5E5E5"}}>
                        <Stack direction={"row"} gap={3} m={3} >
                            <CancelIcon fontSize='large' />
                            <Stack sx={{fontFamily:"Poppins"}}>
                                <Typography sx={{fontFamily:"Poppins"}}>No Contact Found</Typography>
                                <Typography mt={1} sx={{fontFamily:"Poppins"}}>Please add contact from</Typography>
                                <Typography mt={1} sx={{fontFamily:"Poppins"}}>Create Contact Button</Typography>
                            </Stack>
                        </Stack>
                    </Card>
                </Stack>
            }



            <Dialog
                open={openaddDialog}
                onClose={() => setOpenaddDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ textAlign: "center", bgcolor: "black", color: "white", padding: 1 }}>Create Contact</DialogTitle>
                <DialogContent>
                    <Stack>
                        {createContactTexfields.map((obj, index) => (
                            index !== 2 ?
                                <Stack mt={2}>
                                    <Typography> {obj.label} </Typography>
                                    <TextField value={obj.value} onChange={obj.onChangeFn} helperText={obj.errormsg} error={obj.isValid} size='small' sx={{ mt: 0.5 }} />
                                </Stack> :

                                <FormControl sx={{ mt: 2 }}>
                                    <Typography>{obj.label}</Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={status}
                                        onChange={obj.onChangeFn}


                                    >
                                        <FormControlLabel value="Active" control={<Radio style={{ color: "black" }} checked={status === "Active" ? true : false} />} label="Active" />
                                        <FormControlLabel value="Inactive" control={<Radio style={{ color: "black" }} checked={status === "Inactive" ? true : false} />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>


                        ))}
                        {/* <TextField onChange={(e) => getname(e)} helperText={errorName} error={nameValidation} placeholder='Name' size='small' /> */}
                        <Stack alignItems={"center"}>
                            <Button onClick={submitfn} variant='contained' sx={{ mt: 2, bgcolor: "black", borderRadius: "50px", ":hover": { bgcolor: "black" } }}>Save Contact</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openviewDialog}
                onClose={() => setOpenviewDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ textAlign: "center", bgcolor: "black", color: "white", padding: 1 }}>View Contact</DialogTitle>
                <DialogContent>
                    <Stack>
                        {createContactTexfields.map((obj, index) => (
                            index !== 2 ?
                                <Stack mt={2}>
                                    <Typography> {obj.label} </Typography>
                                    <TextField disabled defaultValue={obj.viewValue} size='small' sx={{ mt: 0.5 }} />
                                </Stack> :

                                <FormControl sx={{ mt: 2 }}>
                                    <Typography>{obj.label}</Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"



                                    >
                                        <FormControlLabel control={<Radio disabled style={{ color: "black" }} checked={obj.viewValue === "Active" ? true : false} />} label="Active" />
                                        <FormControlLabel control={<Radio disabled style={{ color: "black" }} checked={obj.viewValue === "Inactive" ? true : false} />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>


                        ))}
                        {/* <TextField onChange={(e) => getname(e)} helperText={errorName} error={nameValidation} placeholder='Name' size='small' /> */}
                        <Stack alignItems={"center"}>
                            <Button onClick={() => setOpenviewDialog(false)} variant='contained' sx={{ mt: 2, bgcolor: "black", borderRadius: "50px", ":hover": { bgcolor: "black" } }}>ok</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openeditDialog}
                onClose={() => setOpeneditDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ textAlign: "center", bgcolor: "black", color: "white", padding: 1 }}>Edit Contact</DialogTitle>
                <DialogContent>
                    <Stack>
                        <Stack>
                            {createContactTexfields.map((obj, index) => (
                                index !== 2 ?
                                    <Stack mt={2}>
                                        <Typography> {obj.label} </Typography>
                                        <TextField value={obj.value} onChange={obj.onChangeFn} size='small' sx={{ mt: 0.5 }} />
                                    </Stack> :

                                    <FormControl sx={{ mt: 2 }}>
                                        <Typography>{obj.label}</Typography>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={status}
                                            onChange={obj.onChangeFn}


                                        >
                                            <FormControlLabel value="Active" control={<Radio style={{ color: "black" }} checked={status === "Active" ? true : false} />} label="Active" />
                                            <FormControlLabel value="Inactive" control={<Radio style={{ color: "black" }} checked={status === "Inactive" ? true : false} />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>


                            ))}
                            {/* <TextField onChange={(e) => getname(e)} helperText={errorName} error={nameValidation} placeholder='Name' size='small' /> */}
                        </Stack>                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mt: 2 }} >
                            <Button onClick={() => setOpeneditDialog(false)} sx={{ color: "black" }}>Cancel</Button>
                            <Button onClick={handleUpdate} variant='contained' sx={{ bgcolor: "black", borderRadius: "50px", ":hover": { bgcolor: "black" } }}>Update</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}


export default HomePage