import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, Box, Button, AppBar, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Typography, Dialog, DialogTitle } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SourceIcon from '@mui/icons-material/Source';


export default function Appbar(props) {
    const [openSourceDialog, setOpenSourceDialog] = useState(false)
    const navigate = useNavigate()
    const drawerWidth = 300;
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    }

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const AppBarData = [
        {
            text: "Contacts",
            icon: <PermContactCalendarIcon />,
            reDirect: () => navigate('/')
        },
        // {
        //     text: "Charts and Maps",
        //     icon: <InsertChartIcon />,
        //     reDirect: () => navigate('/chartsandmaps')
        // },
        {
            text: "Source Code",
            icon: <SourceIcon />,
            reDirect: () => setOpenSourceDialog(true)
        }]




    return (
        <>
            <AppBar sx={{ bgcolor: "black", height: { xs: "10vh", md: "8vh" } }} elevation={0}>
                <Stack justifyContent={"center"} alignItems="center">
                    <Box width={{ xs: "95%", md: "80%" }} mt={1}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                            <Stack direction={"row"} alignItems={"center"} gap={{ xs: 1 }}>
                                {open ? <IconButton onClick={handleDrawerClose}>
                                    <CloseIcon fontSize={"inherit"} sx={{ color: "white", fontSize: { xs: 25, md: 30 } }} />
                                </IconButton> :
                                    <IconButton onClick={handleDrawerOpen}>
                                        <MenuIcon sx={{ color: "white", fontSize: { xs: 25, md: 30 } }} />
                                    </IconButton>}
                            </Stack>
                            <Typography sx={{ fontSize: { xs: 20, md: 25 } }}>{props.pageName}</Typography>
                            <Button onClick={props.btn} variant='contained' sx={{ mt: { xs: 0.2, sm: 0 }, visibility: props.btn ? "inherit" : "hidden", fontSize: { xs: 10, md: 15 }, fontFamily: "sans-serif", fontWeight: "bold", bgcolor: "red", borderRadius: "50px", ":hover": { bgcolor: "red" } }}>Create Contact</Button>
                        </Stack>
                    </Box>
                </Stack>
            </AppBar>

            <Drawer
                sx={{
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: { xs: 250, sm: drawerWidth },
                        mt: 9,
                        borderTopRightRadius: "15px"
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
                onClose={handleDrawerClose}
                onMouseLeave={handleDrawerClose}
            >
                <Box mt={{ xs: 0.2, sm: 3 }}>
                    <List>
                        {AppBarData.map((obj, i) => (
                            <ListItem key={obj} disablePadding sx={{ mt: { xs: 1, sm: 2 } }}>
                                <ListItemButton
                                    onClick={obj.reDirect}
                                >
                                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                                        <Box sx={{ width: "30px", height: "25px" }}>
                                            {obj.icon}
                                        </Box>
                                        <ListItemText primary={obj.text} sx={{ color: "black", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600px", mt: 1 }} />
                                    </Stack>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>

                </Box>
            </Drawer>

            <Dialog open={openSourceDialog} onClose={() => setOpenSourceDialog(false)}>
                <DialogTitle sx={{ bgcolor: "black", color: "white", textAlign: "center" }}>Source Code Link</DialogTitle>
                <Stack direction={{ sm: "row" }} gap={5} alignItems={"center"} sx={{ mt:5, ml:3, mb:3, mr:3 }}>
                    <Button variant='contained' sx={{ bgcolor: "black", borderRadius: "50px", ":hover": { bgcolor: "black" } }} onClick={() => window.open("https://gitlab.com/netcomithrismohamed/taiyo_task/-/tree/master?ref_type=heads")}>gitlab</Button>
                    <Button variant='contained' sx={{ bgcolor: "black", borderRadius: "50px", ":hover": { bgcolor: "black" } }} onClick={() => window.open("https://drive.google.com/file/d/1FuKjcwfI5mAO48fjrH_b4ZL3eou9njMu/view?usp=sharing")}>Google Drive</Button>
                </Stack>
                <Stack alignItems={"center"} mt={3}>
                    <Button size='small' variant='outlined' onClick={() => setOpenSourceDialog(false)} sx={{ borderRadius: "50px", textTransform: "capitalize", borderColor: "red", mb: 2, color: "black", ":hover": { bgcolor: "black", borderColor: "red", color: "white" } }}>Close</Button>
                </Stack>
            </Dialog>
        </>
    )
}