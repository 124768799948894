import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import { Box, Stack, Typography } from '@mui/material';
import {CurrentPieChartsData, setPieChartsData} from '../../../redux/pieChartsDataSlice';
import { useDispatch, useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart() {
    const pieChatsData = useSelector(CurrentPieChartsData)
    const dispatch = useDispatch()

    const wantedKeys = ["cases", "deaths", "recovered", "active", "critical"];

    const wantedTodayKeys = ["todayCases", "todayRecovered", "todayDeaths"];

    const filteredData = Object.fromEntries(
        Object.entries(pieChatsData).filter(([key]) => wantedKeys.includes(key))
    );

    const filteredTodayData = Object.fromEntries(
        Object.entries(pieChatsData).filter(([key]) => wantedTodayKeys.includes(key))
    );

    const dataKey = Object.keys(filteredData);
    const dataValue = Object.values(filteredData);

    const dataTodayKey = Object.keys(filteredTodayData);
    const dataTodayValue = Object.values(filteredTodayData);

    function generateRandomDarkColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            var randomIndex = Math.floor(Math.random() * letters.length);
            color += letters[randomIndex];
        }
        return color;
    }

    function generateDarkColorArray(numColors) {
        var colors = [];
        for (var i = 0; i < numColors; i++) {
            var color = generateRandomDarkColor();
            colors.push(color);
        }
        return colors;
    }

    var numColors = dataValue.length // Specify the number of colors you want to generate
    var numColors2 = dataTodayValue.length
    var colorArray = generateDarkColorArray(numColors);
    var colorArray2 = generateDarkColorArray(numColors2);


    const data = {
        labels: dataKey,
        datasets: [
            {
                label: "Counts",
                data: dataValue,
                backgroundColor: colorArray,
                borderColor: colorArray,
                borderWidth: 1,
            },
        ],
    };

    console.log(dataKey.length)
    console.log(dataValue.length)



    const Todaydata = {
        labels: dataTodayKey,
        datasets: [
            {
                label: "Counts",
                data: dataTodayValue,
                backgroundColor: colorArray2,
                borderColor: colorArray2,
                borderWidth: 1,
            },
        ],
    };



    useEffect(() => {
        axios.get("https://disease.sh/v3/covid-19/all").then((res) => {
            dispatch(setPieChartsData(res.data))
        })
    }, [])

    return (
        <>
            <Typography sx={{fontFamily:"ubuntu",fontWeight:"bold",fontSize:{xs:"18px", md:"30px", lg:"40px"}}}>CASES FLUCTUATIONS</Typography>
            <Stack direction={{md:"row"}} alignItems={"center"} justifyContent={"center"} gap={10} mt={3}>
                <Stack>
                <Typography>Overall Report</Typography>
                <Pie data={data} />
                </Stack>
                <Stack>
                <Typography>Today Report</Typography>
                <Pie data={Todaydata} />
                </Stack>
            </Stack>
        </>
    )
}

export default PieChart