import { createSlice } from "@reduxjs/toolkit";

const pieChartsDataSlice = createSlice({
    name: "pieChartsData",
    initialState: {
        pieChartsData: "",
    },
    reducers: {
        setPieChartsData: (state, action) => {
            state.pieChartsData = action.payload;
        }
    }
})

export const { setPieChartsData } = pieChartsDataSlice.actions;


export const CurrentPieChartsData = (state) => state.pieChartsData.pieChartsData

export default pieChartsDataSlice.reducer;
